// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`
 
export const updateAlert = createAsyncThunk('ticketSuporte/updateAlert', async (props, { dispatch, getState }) => {
  return {text:props.text, variant:props.variant}
})

export const setAlertTrigger = createAsyncThunk('ticketSuporte/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const postSuporte = createAsyncThunk('ticketSuporte/postSuporte', async obj => {
  const response = await axios.post(`${API_BASE_URL}api/suporte/create`, obj)
  return response
})

export const updateTicket = createAsyncThunk('ticketSuporte/updateTicket', async obj => {
  const uuid = obj.uuid
  delete obj.uuid
  const response = await axios.put(`${API_BASE_URL}api/ticketsuporte/updatelido?uuid=${uuid}`, obj)
  return response
})

export const getMails = createAsyncThunk('ticketSuporte/getMails', async params => {
  const  response = await axios.get(`${API_BASE_URL}api/ticketsuporte/listadmin?limit=${params?.perPage}&offset=${params?.page}`)
  return {
    params,
    data: response.data.ticket,
    totalPages: response.data.ordenate.totalPage
  }
})

export const updateMails = createAsyncThunk(
  'appEmail/updateMails',
  async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
    const response = await axios.post('/apps/email/update-emails', { emailIds, dataToUpdate })
    await dispatch(getMails(getState().email.params))
    return {
      emailIds,
      dataToUpdate,
      data: response.data
    }
  }
)

export const updateMailLabel = createAsyncThunk(
  'appEmail/updateMailLabel',
  async ({ emailIds, label }, { dispatch, getState }) => {
    const response = await axios.post('/apps/email/update-emails-label', { emailIds, label })
    await dispatch(getMails(getState().email.params))
    return response.data
  }
)

export const paginateMail = createAsyncThunk('appEmail/paginateMail', async ({ dir, emailId }) => {
  const response = await axios.get('/apps/email/paginate-email', { params: { dir, emailId } })
  return response.data
})

export const selectCurrentMail = createAsyncThunk('ticketSuporte/selectCurrentMail', async id => {

  const response = await axios.get(`${API_BASE_URL}api/ticketsuporte/search?search=${id}`)
  if (response.status === 200) {
    const resposta = response.data.ticket[0].mensagens
    
    const ticketAberto = resposta.shift()
    const data = {
      uuid: response.data.ticket[0].uuid,
      criadoEm: response.data.ticket[0].criadoEm,
      ticket: ticketAberto,
      respostas: resposta,
      medico: response.data.ticket[0].medico
    }
    return data
  }
})

export const appEmailSlice = createSlice({
  name: 'ticketSuporte',
  initialState: {
    mails: [],
    total: 1,
    params: {},
    responseTicket: {},
    emailsMeta: {},
    selectedMails: [],
    currentMail: null,
    alertProps:{text:"", variant:""},
    alertTrigger:false
  },
  reducers: {
    selectMail: (state, action) => {
      const selectedMails = state.selectedMails
      if (!selectedMails.includes(action.payload)) {
        selectedMails.push(action.payload)
      } else {
        selectedMails.splice(selectedMails.indexOf(action.payload), 1)
      }
      state.selectedMails = selectedMails
    },
    selectAllMail: (state, action) => {
      const selectAllMailsArr = []
      if (action.payload) {
        selectAllMailsArr.length = 0
        state.mails.forEach(mail => selectAllMailsArr.push(mail.id))
      } else {
        selectAllMailsArr.length = 0
      }
      state.selectedMails = selectAllMailsArr
    },
    resetSelectedMail: state => {
      state.selectedMails = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getMails.fulfilled, (state, action) => {
        state.mails = action.payload.data
        state.params = action.payload.params 
        state.total = action.payload.totalPages
      })
      .addCase(updateMails.fulfilled, (state, action) => {
        function updateMailData(email) {
          Object.assign(email, action.payload.dataToUpdate)
        }
        state.mails.forEach(email => {
          if (action.payload.emailIds.includes(email.id)) {
            updateMailData(email)
          }
        })
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(postSuporte.fulfilled, (state, action) => {
        state.responseTicket = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(paginateMail.fulfilled, (state, action) => {
        const data = action.payload
        const dataIndex = state.mails.findIndex(i => i.id === data.id)
        dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
        dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
        state.currentMail = data
      })
      .addCase(selectCurrentMail.fulfilled, (state, action) => {
        state.currentMail = action.payload
      })
  }
})

export const { selectMail, selectAllMail, resetSelectedMail } = appEmailSlice.actions

export default appEmailSlice.reducer
