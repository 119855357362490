// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'

export const getCurrentQuestionnaire = createAsyncThunk('appQuestionnaires/getCurrentQuestionnaire', async params => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/modeloquestionario/search?offset=0&limit=10&orderBy=nome::asc&search=${params.uuid}`)

  return {
    params,
    data: response.data.modelo_questionario[0],
    totalPages: response.data.ordenate.totalPage
  }
})

export const setAlertTrigger = createAsyncThunk('appQuestionnaires/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const updateAlert = createAsyncThunk('appQuestionnaires/updateAlert', async (props, { dispatch, getState }) => {
  return { text: props.text, variant: props.variant }
})

export const updateCurrentQuestionnaire = createAsyncThunk('appQuestionnaires/updateCurrentQuestionnaire', async (currentQuestionnaire, { dispatch, getState }) => {
  return currentQuestionnaire
})

export const getData = createAsyncThunk('appQuestionnaires/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/modeloquestionario/list?offset=${params?.page ? params.page : 0}&limit=${params?.perPage ? params.perPage : 10}&orderBy=nome::asc`)
  
  return {
    params,
    data: response?.data?.modelo_questionario,
    totalPages: response.data.ordenate.totalItem
  }
})

export const updateStatus = createAsyncThunk('appQuestionnaires/updateStatus', async (params, { dispatch, getState }) => {
  await axios.put(`${process.env.REACT_APP_API_URL}api/modeloquestionario/update?uuid=${params.uuid}`, {
    status: !params.status
  })

  await dispatch(getData(getState()))
  return params
})

export const deleteQuestionnaire = createAsyncThunk('appQuestionnaires/deleteQuestionnaire', async (params, { dispatch, getState }) => {
  //Apagando Questionário
  await axios.put(`${process.env.REACT_APP_API_URL}api/modeloquestionario/delete?uuid=${params.uuid}`)

  await dispatch(getData(getState()))
  await dispatch(updateAlert({ text: "Modelo de questionário excluído com sucesso", variant: "danger" }))
  await dispatch(setAlertTrigger(true))
})

export const updateQuestionnaire = createAsyncThunk('appQuestionnaires/updateCustomer', async (questionnaire, { dispatch, getState }) => {
  //Atualizando no backEnd - "modelo questionário"
  await axios.put(`${process.env.REACT_APP_API_URL}api/modeloquestionario/update?uuid=${questionnaire.uuid}`, questionnaire.questionario)

  //Atualizando no backEnd - "perguntas"
  if (Array.isArray(questionnaire.perguntas) && questionnaire.perguntas.length > 0) {
    await axios.put(`${process.env.REACT_APP_API_URL}api/pergunta/update`, questionnaire.perguntas)
  }

  //Atualizando no backEnd - "respostas"
  if (Array.isArray(questionnaire.respostas) && questionnaire.respostas.length > 0) {
    await axios.put(`${process.env.REACT_APP_API_URL}api/resposta/update`, questionnaire.respostas)
  }

  //Atualizando no backEnd - "resultados"
  if (Array.isArray(questionnaire.resultados) && questionnaire.resultados.length > 0) {
    await axios.put(`${process.env.REACT_APP_API_URL}api/resultado/update`, questionnaire.resultados)
  }

  //Atualizando no backEnd - "Perguntas excluídas"
  if (Array.isArray(questionnaire.perguntasParaExcluir) && questionnaire.perguntasParaExcluir.length > 0) {
    await axios.put(`${process.env.REACT_APP_API_URL}api/pergunta/update`, questionnaire.perguntasParaExcluir)
  }

  //Atualizando no backEnd - "Resultados excluídos"
  if (Array.isArray(questionnaire.resultadosParaExcluir) && questionnaire.resultadosParaExcluir.length > 0) {
    await axios.put(`${process.env.REACT_APP_API_URL}api/resultado/update`, questionnaire.resultadosParaExcluir)
  }

  await dispatch(updateAlert({ text: "Alterações realizadas com sucesso", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return questionnaire
})

export const addQuestionnaire = createAsyncThunk('appQuestionnaires/addQuestionnaire', async (questionnaire, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}api/modeloquestionario/create`, questionnaire)

  await dispatch(updateAlert({ text: "Modelo de questionário cadastrado com sucesso", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return questionnaire
})

export const deleteUser = createAsyncThunk('appCustomers/deleteUser', async params => {

})

export const appQuestionnairesSlice = createSlice({
  name: 'appQuestionnaires',
  alertProps: { text: "", variant: "" },
  alertTrigger: false,
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    currentQuestionnaire: {
      step: 1,
      data: {}
    }
  },

  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(updateCurrentQuestionnaire.fulfilled, (state, action) => {
        state.currentQuestionnaire = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(getCurrentQuestionnaire.fulfilled, (state, action) => {
        state.currentQuestionnaire = action.payload
      })
  }
})

export default appQuestionnairesSlice.reducer
