// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'

const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const getData = createAsyncThunk('appUserProfile/getData', async params => { 
  const response = await axios.get(`${API_BASE_URL}api/perfilusuario/list?offset=${params?.page}&limit=${params?.perPage}&orderBy=nome::asc`)
  
  return {
    params,
    data: response?.data?.perfil_usuario,
    totalPages: response.data.ordenate.totalItem
  }
})

export const getCurrentRegister = createAsyncThunk('appUserProfile/getCurrentRegister', async params => {
  const response = await axios.get(`${API_BASE_URL}api/perfilusuario/search?offset=0&limit=10&orderBy=nome::asc&search=${params.uuid}`)
  return {
    params,
    data: response.data.perfil_usuario,
    totalPages: response.data.ordenate.totalPage
  }
})

export const updateCurrentRegister = createAsyncThunk('appUserProfile/updateCurrentRegister', async (currentUserProfile, { dispatch, getState }) => {
  return currentUserProfile
})

export const deleteUser = createAsyncThunk('appUserProfile/deleteUser', async params => {

})

export const updateAlert = createAsyncThunk('appUserProfile/updateAlert', async (props, { dispatch, getState }) => {
  return { text: props.text, variant: props.variant }
})

export const setAlertTrigger = createAsyncThunk('appUserProfile/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const addUserProfile = createAsyncThunk('appUserProfile/addUserProfile', async (userProfile, { dispatch, getState }) => {
  await axios.post(`${API_BASE_URL}api/perfilusuario/create`, userProfile)
  await dispatch(updateAlert({ text: "Perfil cadastrado com sucesso!", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return modelo
})

export const deleteUserProfile = createAsyncThunk('appUserProfile/deleteUserProfile', async (idProfile, { dispatch, getState }) => {
  const response = await axios.put(`${API_BASE_URL}api/perfilusuario/delete?uuid=${idProfile}`, { deletado: true })
  await dispatch(updateAlert({ text: "Perfil excluído com sucesso!", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
})

export const updateUserProfile = createAsyncThunk('appUserProfile/updateUserProfile', async (userProfile, { dispatch, getState }) => {
  //Atualizando no backEnd - "perfil_usuario"
  await axios.put(`${API_BASE_URL}api/perfilusuario/update?uuid=${userProfile?.perfilUsuario?.uuid}`, {
    nome: userProfile.perfilUsuario.nome,
    status: userProfile.perfilUsuario.status
  })

  //Atualizando no backEnd - "perfil_permissoes"
  await axios.put(`${API_BASE_URL}api/perfilpermissao/update`, userProfile.perfilPermissoes)

  await dispatch(updateAlert({ text: "Alterações salvas com sucesso!", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return userProfile
})

export const updateStatus = createAsyncThunk('appUserProfile/updateUserProfile', async (params, { dispatch, getState }) => {
  const response = await axios.put(`${API_BASE_URL}api/perfilusuario/update?uuid=${params.uuid}`, {
    status:!params.status
  })

  await dispatch(getData(getState()))
  return params
})

export const getAllLimits = createAsyncThunk('appUserProfile/getAllLimits', async params => {
  const response = await axios.get(`${API_BASE_URL}api/perfillimite/list`)
  return response?.data?.perfil_limite
})

export const appUsersSlice = createSlice({
  name: 'appUserProfile',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    resources: [],
    paymentForms: [],
    resourceSelected: {},
    currentRegisterSelected: {}, //In this case you can understand as "currentUserProfile"
    modal: false,
    alertProps: { text: "", variant: "" },
    alertTrigger: false,
    paymentFormsSelected: [],
    invoicingData: [],
    invoicingCreated: [],
    limits: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(updateCurrentRegister.fulfilled, (state, action) => {
        state.currentUserProfile = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(getCurrentRegister.fulfilled, (state, action) => {
        state.currentRegisterSelected = action.payload
      })
      .addCase(getAllLimits.fulfilled, (state, action) => {
        state.limits = action.payload
      })
  }
})

export default appUsersSlice.reducer