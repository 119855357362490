// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import chat from '@src/views/apps/chat/store'
import users2 from '@src/views/pages/users/store'
import modeloAvaliacao from '@src/views/pages/evaluations/store' 
import chatConfigs from '@src/views/pages/configs/chat/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import categories from '@src/views/pages/configs/categories/store'
import plans from '@src/views/pages/plans/store'
import modelRehab from '@src/views/pages/rehabilitations/store'
import userProfile from '@src/views/pages/profiles/store'
import customers from '@src/views/pages/customers/store'
import accessions from '@src/views/pages/accession/store'
import questionnaires from '@src/views/pages/questionnaires/store'
import myprofile from '@src/views/pages/my-profile/store'
import ticketsuporte from '@src/views/dashboard/tickets/store'
import home from '@src/views/dashboard/home/store'
import overview from '@src/views/pages/overview/store'

const rootReducer = {
  auth,
  chat,
  email,
  users2,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  modeloAvaliacao,
  chatConfigs,
  categories,
  plans,
  modelRehab,
  userProfile,
  customers,
  accessions,
  questionnaires,
  myprofile,
  ticketsuporte,
  home,
  overview
}

export default rootReducer
