// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'

export const getMails = createAsyncThunk('appEmail/getMails', async params => {
  const response = await axios.get('/apps/email/emails', { params })
  return {
    params,
    data: response.data
  }
})
 
export const getAdesoesGrafico = createAsyncThunk('dashboardAdmin/getAdesoesGrafico', async params => {

  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/dashboardadmin/adesoes?firstDate=${params}`)
  return {
    datas: response.data.datas,
    quantidade: response.data.quantidades
  }
})
 
export const getClienteNavbar = createAsyncThunk('dashboardAdmin/getClienteNavbar', async params => {

  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/clientes/navbaradmin?date=${params.data}`)
  
  return {
    data: response.data.clientes,
    quantidade: response.data.ordenate.totalItem
  }
})

export const getClientes = createAsyncThunk('dashboardAdmin/getClientes', async params => {
  let result
  if (params?.filtro !== '1') {
    result = await axios.get(`${process.env.REACT_APP_API_URL}api/dashboardadmin/filter?offset=${params?.page ? params.page : 0}&limit=${params?.perPage ? params.perPage : 10}&search=${params?.filtro}&orderBy=nome_nomeResponsavel::asc`)
  } else {
    result = await axios.get(`${process.env.REACT_APP_API_URL}api/dashboardadmin/clientes?offset=${params?.page ? params.page : 0}&limit=${params?.perPage ? params.perPage : 10}&orderBy=nome_nomeResponsavel::asc`)
  }
  
  return {
    data: result.data.lista_clientes,
    total: result.data.ordenate.totalItem
  }
})

export const getTicketNavbar = createAsyncThunk('dashboardAdmin/getTicketNavbar', async params => {

  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/ticketsuporte/navbarticketadmin?date=${params.data}`)

  return {
    data: response.data.ticket,
    quantidade: response.data.ordenate.totalItem
  }
})

export const getTicketAndamento = createAsyncThunk('dashboardAdmin/getTicketAndamento', async params => {

  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/dashboardadmin/dashboardadminandamento?limit=${params?.perPage}&offset=${params?.page}`)
  
  return {
    data: response.data.andamento,
    total: response.data.ordenate.totalItem
  }
})

export const getTicketRecente = createAsyncThunk('dashboardAdmin/getTicketRecente', async params => {

  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/dashboardadmin/dashboardadminrecentes?limit=${params?.perPage}&offset=${params?.page}`)
  return {
    data: response.data.recentes,
    total: response.data.ordenate.totalItem
  }
})

export const updateMails = createAsyncThunk(
  'appEmail/updateMails',
  async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
    const response = await axios.post('/apps/email/update-emails', { emailIds, dataToUpdate })
    await dispatch(getMails(getState().email.params))
    return {
      emailIds,
      dataToUpdate,
      data: response.data
    }
  }
)

export const updateMailLabel = createAsyncThunk(
  'appEmail/updateMailLabel',
  async ({ emailIds, label }, { dispatch, getState }) => {
    const response = await axios.post('/apps/email/update-emails-label', { emailIds, label })
    await dispatch(getMails(getState().email.params))
    return response.data
  }
)

export const paginateMail = createAsyncThunk('appEmail/paginateMail', async ({ dir, emailId }) => {
  const response = await axios.get('/apps/email/paginate-email', { params: { dir, emailId } })
  return response.data
})

export const selectCurrentMail = createAsyncThunk('appEmail/selectCurrentMail', async id => {
  const response = await axios.get('/apps/email/get-email', { id })
  return response.data
})

export const appEmailSlice = createSlice({
  name: 'dashboardAdmin',
  initialState: {
    dataGrafic: [],
    quantidadeGrafic: [],
    ticketAndamento: [],
    totalAndamento: 0,
    ticketRecente: [],
    totalRecente: 0,
    listaClientes: [],
    totalClientes: 0,
    clienteCount: 0,
    clienteHeader: [],
    ticketCount: 0,
    ticketHeader: [],
    params: {},
    emailsMeta: {},
    selectedMails: [],
    currentMail: null
  },
  extraReducers: builder => { 
    builder 
      .addCase(getAdesoesGrafico.fulfilled, (state, action) => {
        state.dataGrafic = action.payload.datas
        state.quantidadeGrafic = action.payload.quantidade
      })
      .addCase(getClientes.fulfilled, (state, action) => {
        state.listaClientes = action.payload.data
        state.totalClientes = action.payload.total
      })
      .addCase(getTicketAndamento.fulfilled, (state, action) => {
        state.ticketAndamento = action.payload.data
        state.totalAndamento = action.payload.total
      })
      .addCase(getTicketRecente.fulfilled, (state, action) => {
        state.ticketRecente = action.payload.data
        state.totalRecente = action.payload.total
      })
      .addCase(getClienteNavbar.fulfilled, (state, action) => {
        state.clienteHeader = action.payload.data
        state.clienteCount = action.payload.quantidade        
      })
      .addCase(getTicketNavbar.fulfilled, (state, action) => {
        state.ticketHeader = action.payload.data
        state.ticketCount = action.payload.quantidade        
      })
      .addCase(updateMails.fulfilled, (state, action) => {
        function updateMailData(email) {
          Object.assign(email, action.payload.dataToUpdate)
        }
        state.mails.forEach(email => {
          if (action.payload.emailIds.includes(email.id)) {
            updateMailData(email)
          }
        })
      })
      .addCase(paginateMail.fulfilled, (state, action) => {
        const data = action.payload
        const dataIndex = state.mails.findIndex(i => i.id === data.id)
        dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
        dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
        state.currentMail = data
      })
      .addCase(selectCurrentMail.fulfilled, (state, action) => {
        state.currentMail = action.payload
      })
  }
})

export const { selectMail, selectAllMail, resetSelectedMail } = appEmailSlice.actions

export default appEmailSlice.reducer
