// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getFormData } from '../../../../utility/Utils'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`
// export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
//   const response = await axios.get('/api/users/list/all-data')
//   return response.data
// })

export const updateAlert = createAsyncThunk('appUsers/updateAlert', async (props, { dispatch, getState }) => {
  return {text:props.text, variant:props.variant, showAlert:props.showAlert}
})
export const setAlertTrigger = createAsyncThunk('appUsers/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get(`${API_BASE_URL}api/planos/list?offset=${params?.page}&limit=${params?.perPage}&orderBy=nome::asc`)

  return {
    params,
    data: response.data.planos,
    totalPages: response.data.ordenate.totalItem
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const getResource = createAsyncThunk('appUsers/getResource', async resource => {
  return resource
})

export const addPlan = createAsyncThunk('appUsers/addPlan', async (data, { dispatch, getState }) => {
  const response  = await axios.post(`${API_BASE_URL}api/planos/create`, data)
  const responseData = response.data.data.insert_planos.returning[0]
  await dispatch(getData(getState()))
  return responseData
})

export const getPlan = createAsyncThunk('appUsers/getPlan', async (data, { dispatch, getState }) => {
  return data
})

export const getResources = createAsyncThunk('appUsers/getResources', async (params, { dispatch, getState }) => {
  const response = await axios.get(`${API_BASE_URL}api/planos/search?offset=0&limit=50&search=${params.currentPlan}`)
  return {
    params,
    data: response.data.planos[0].recursos_do_planos,
    totalPages: Math.ceil(response.data.planos[0].recursos_do_planos.length / 10)
  }
})

export const getPaymentForms = createAsyncThunk('appUsers/getPaymentForms', async (params, { dispatch, getState }) => {
  const response = await axios.get(`${API_BASE_URL}api/planos/search?offset=0&limit=50&search=${params.currentPlan}`)
  return {
    params,
    data: response.data.planos[0].formas_pagamento,
    totalPages: Math.ceil(response.data.planos[0].formas_pagamento.length / 10)
  }
})

export const addResource = createAsyncThunk('appUsers/addResource', async (data, { dispatch, getState }) => {
  const {cadeiraReabilitacao, cadeiraAvaliacao} = data
  const dataWithUuid = {cadeiraAvaliacao, cadeiraReabilitacao, plano: data?.currentPlan}
  await axios.post(`${API_BASE_URL}api/recursosdoplano/create`, dataWithUuid)
  await dispatch(getResources(data))
  return data
})

export const addPaymentForms = createAsyncThunk('appUsers/addPaymentForms', async (data, { dispatch, getState }) => {
  const response = await axios.post(`${API_BASE_URL}api/formapagamento/create`, getFormData({
    formasPagamento: [...data]
  }))
  return response.data.data.insert_formas_pagamento_plano.returning
})

export const addInvoicing = createAsyncThunk('appUsers/addInvoicing', async (data, { dispatch, getState }) => {
  const response = await axios.post(`${API_BASE_URL}api/faturamento/create`, data)
  await dispatch(getResources(getState()))
  return response.data.data.insert_faturamento.returning
})
export const updateInvoicing = createAsyncThunk('appUsers/updateInvoicing', async (data, { dispatch, getState }) => {
  const response = await axios.put(`${API_BASE_URL}api/faturamento/update`, data)
  await dispatch(getPlan(getState()))
  return response
})

export const deleteInvoicing = createAsyncThunk('appUsers/deleteInvoicing', async (data, { dispatch, getState }) => {
  const response = await axios.put(`${API_BASE_URL}api/faturamento/update`, data)
  return response
})

export const updatePlan = createAsyncThunk('appUsers/updatePlan', async (plan, { dispatch, getState }) => {
  const {data, uuid} = plan
  await axios.put(`${API_BASE_URL}api/planos/update?uuid=${uuid}`, data)
  await dispatch(getData(getState()))
  return plan
})

export const deletePlan = createAsyncThunk('appUsers/deletePlan', async (id, { dispatch, getState }) => {
  const response = await axios.put(`${API_BASE_URL}api/planos/delete?uuid=${id}`)
  await dispatch(getData(getState()))
  return response
})

export const updateResource = createAsyncThunk('appUsers/updateResourse', async (resource, { dispatch, getState }) => {
  await axios.put(`${API_BASE_URL}api/recursosdoplano/update?uuid=${resource.uuid}`, resource.data)
  await dispatch(getResources(resource))
  return resource
})

export const updatePaymentForms = createAsyncThunk('appUsers/updatePaymentForms', async (params, { dispatch, getState }) => {
  const response = await axios.put(`${API_BASE_URL}api/formapagamento/update`, getFormData({
    formasPagamento: [...params.data]
  }))
  await dispatch(updateAlert({text:"Formas de pagamento atualizado com sucesso", variant:"success"}))
  await dispatch(setAlertTrigger(true))
  return response
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const deleteResource = createAsyncThunk('appUsers/deleteResource', async (params, { dispatch, getState }) => {
  await axios.put(`${API_BASE_URL}api/recursosdoplano/delete?uuid=${params.uuid}`)
  await dispatch(getResources(params))
  return uuid
})

export const updatePlanData = createAsyncThunk('appUsers/updatePlanData', async (plan, { dispatch, getState }) => {
  return plan
})

export const updateInvoicingSelected = createAsyncThunk('appUsers/updateInvoicingSelected', async (invoicing, { dispatch, getState }) => {
  return invoicing
})

export const setModal = createAsyncThunk('appUsers/setModal', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const updateDropdownValues = createAsyncThunk('appUsers/updateDropdownValues', async (values, { dispatch, getState }) => {
  return values
})

export const updateDropdownValuesPayment = createAsyncThunk('appUsers/updateDropdownValuesPayment', async (values, { dispatch, getState }) => {
  return values
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    planData:{step:1, data:{}},
    resources:[],
    paymentForms: [],
    resourceSelected:{},
    planSelected:{},
    modal:false,
    alertProps:{text:"", variant:""},
    alertTrigger:false,
    paymentFormsSelected:[],
    invoicingData:[],
    invoicingCreated:[],
    dropdownValuesInvoicing:[],
    dropdownValuesPayment:[]

  },
  reducers: {},
  extraReducers: builder => {
    builder
      // .addCase(getAllData.fulfilled, (state, action) => {
      //   state.allData = action.payload
      // })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPaymentForms.fulfilled, (state, action) => {
        state.paymentForms = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getResources.fulfilled, (state, action) => {
        state.resources = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getPlan.fulfilled, (state, action) => {
        state.planSelected = action.payload
      })
      .addCase(updatePlanData.fulfilled, (state, action) => {
        state.planData = action.payload
      })
      .addCase(updateInvoicingSelected.fulfilled, (state, action) => {
        state.invoicingData = action.payload
      })
      .addCase(setModal.fulfilled, (state, action) => {
        state.modal = action.payload
      })
      .addCase(getResource.fulfilled, (state, action) => {
        state.resourceSelected = action.payload
      })
      .addCase(addPlan.fulfilled, (state, action) => {
        state.planSelected = action.payload
      })
      .addCase(addPaymentForms.fulfilled, (state, action) => {
        state.paymentFormsSelected = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(updateDropdownValuesPayment.fulfilled, (state, action) => {
        state.dropdownValuesPayment = action.payload
      })
      .addCase(updateDropdownValues.fulfilled, (state, action) => {
        state.dropdownValuesInvoicing = action.payload
      })
      .addCase(addInvoicing.fulfilled, (state, action) => {
        state.invoicingCreated = action.payload
      })
  }
})

export default appUsersSlice.reducer
