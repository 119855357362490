// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`
export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const { perPage, page } = params
  const response = await axios.get(`${API_BASE_URL}api/categoriaatividade/list?offset=${page}&limit=${perPage}&orderBy=nome::asc`)

  return {
    params,
    data: response.data.categoria_atividade,
    totalPages: response.data.ordenate.totalItem
  }
})

export const updateAlert = createAsyncThunk('appUsers/updateAlert', async (props, { dispatch, getState }) => {
  return {text:props.text, variant:props.variant, showAlert:props.showAlert}
})
export const setAlertTrigger = createAsyncThunk('appUsers/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const getCategorie = createAsyncThunk('appUsers/getCategorie', async categorie => {
 return categorie
})

export const setToggleSidebar = createAsyncThunk('appUsers/setToggleSidebar', async toggle => {
 return toggle
})

export const addCategorie = createAsyncThunk('appUsers/addCategorie', async (categorie, { dispatch, getState }) => {
  await dispatch(setAlertTrigger(true))
  await dispatch(updateAlert({text:" categoria adicionada com sucesso", variant:"success"}))
  await axios.post(`${API_BASE_URL}api/categoriaatividade/create`, categorie)
  await dispatch(getData(getState()))
  return categorie
})
export const updateCategorie = createAsyncThunk('appUsers/updateCategorie', async (categorie, { dispatch, getState }) => {
  const {uuid, data} = categorie
 // quando não alterar o status
  if (typeof Object.values(data)[0] !== 'boolean') {
    await dispatch(setAlertTrigger(true))
    await dispatch(updateAlert({text:"categoria atualizada com sucesso", variant:"success"}))
  }
  await axios.put(`${API_BASE_URL}api/categoriaatividade/update?uuid=${uuid}`, data)
  await dispatch(getData(getState()))
  return categorie
})

export const deleteCategorie = createAsyncThunk('appUsers/deleteUser', async (uuid, { dispatch, getState }) => {
  await dispatch(setAlertTrigger(true))
  await dispatch(updateAlert({text:" categoria excluída com sucesso", variant:"danger"}))
  await axios.put(`${API_BASE_URL}api/categoriaatividade/delete?uuid=${uuid}`)
  await dispatch(getData(getState()))
  return uuid
})


export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCategorie: {},
    sidebarOpen:false,
    alertProps:{text:"", variant:""},
    alertTrigger:false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCategorie.fulfilled, (state, action) => {
        state.selectedCategorie = action.payload
      })
      .addCase(setToggleSidebar.fulfilled, (state, action) => {
        state.sidebarOpen = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
  }
})

export default appUsersSlice.reducer
