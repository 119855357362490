import * as axiosCustom from "axios"

const axios = axiosCustom.create({
  // baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json"
  },
  withCredentials: true
})

axios.interceptors.request.use(
  (config) => {
    if (!config.url.endsWith("login")) {
      config.withCredentials = true
      config.maxBodyLength = 100000
      config.maxContentLength = 100000

      return config
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    response.withCredentials = true
    response.maxBodyLength = 100000
    response.maxContentLength = 100000

    return response
  },
  (error) => {
    if (error.response.status === 401) {
      const requestConfig = error.config

      localStorage.removeItem("userData")
      localStorage.setItem("@expired", "true")

      window.location.href = '/'

      return axios(requestConfig)
    }
    return Promise.reject(error)
  }
)

export default axios
