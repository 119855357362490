// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const getCurrentCustomer = createAsyncThunk('appCustomers/getCurrentCustomer', async params => {
  const response = await axios.get(`${API_BASE_URL}api/clientes/search?offset=0&limit=10&orderBy=nome::asc&search=${params.uuid}`)

  return {
    params,
    data: response.data.clientes,
    totalPages: response.data.ordenate.totalPage
  }
})

export const getClientesAsaas = createAsyncThunk('appCustomers/getClientesAsaas', async params => {
  const response = await axios.get(`${API_BASE_URL}api/clientes/clientesasaas`)
  console.log('response', response)
  return response
})

export const getWebhook = createAsyncThunk('appCustomers/getWebhook', async params => {
  const response = await axios.get(`${API_BASE_URL}api/clientes/webhook?offset=0&limit=10`)

  return response
})

export const postWebhook = createAsyncThunk('appCustomers/postWebhook', async params => {
  const web = {
    teste: 'alou'
  }
  const response = await axios.post(`${API_BASE_URL}api/webhookcobranca?offset=0&limit=10`, web)

  return response
})

export const testeWebhook = createAsyncThunk('appCustomers/testeWebhook', async params => {
  const response = await axios.get(`${API_BASE_URL}api/clientes/webhookcreate?offset=0&limit=10`)

  return response
})

export const setAlertTrigger = createAsyncThunk('appCustomers/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const updateAlert = createAsyncThunk('appUsers/updateAlert', async (props, { dispatch, getState }) => {
  return { text: props.text, variant: props.variant }
})

export const updateCurrentCustomer = createAsyncThunk('appCustomers/updateCurrentCustomer', async (currentCustomer, { dispatch, getState }) => {
  return currentCustomer
})

export const getData = createAsyncThunk('appCustomers/getData', async params => {
  let searchTerm = ``
  if (params?.q?.planoId) {
    searchTerm += `&planoId=${params?.q?.planoId}`
  }

  let response = ''
  if (searchTerm === '') {
    response = await axios.get(`${API_BASE_URL}api/clientes/list?offset=${params?.page}&limit=${params?.perPage}&orderBy=nome::asc`)
  } else {
    response = await axios.get(`${API_BASE_URL}api/clientes/filter?offset=${params?.page}&limit=${params?.perPage}${searchTerm}&orderBy=nome::asc`)
  }

  return {
    params,
    data: response.data.clientes,
    totalPages: response.data.ordenate.totalItem
  }
})

export const updateStatus = createAsyncThunk('appCustomers/updateStatus', async (params, { dispatch, getState }) => {
  const response = await axios.put(`${API_BASE_URL}api/clientes/update?uuid=${params.uuid}`, {
    status: !params.status
  })

  await dispatch(getData({page: 0, perPage: 10}))
  return params
})

export const deleteCustomer = createAsyncThunk('appCustomers/deleteCustomer', async (customer, { dispatch, getState }) => {
  //Apagando Cliente
  await axios.put(`${API_BASE_URL}api/clientes/delete?uuid=${customer.uuid}&clientAsaasId=${customer.clientAsaasId}`)

  //Apagando endereços vinculados ao cliente
  await axios.put(`${API_BASE_URL}api/enderecos/deleteByClientId?clienteId=${customer.uuid}`)

  //Apagando credenciamentos vinculados a este cliente
  await axios.put(`${API_BASE_URL}api/credenciamento/deleteByClientId?cliente=${customer.uuid}`, [])

  await dispatch(getData({page: 0, perPage: 10}))
  await dispatch(updateAlert({ text: "Cliente excluído com sucesso", variant: "success" }))
  await dispatch(setAlertTrigger(true))
})

export const updateCustomer = createAsyncThunk('appCustomers/updateCustomer', async (customer, { dispatch, getState }) => {
  const newCustomer = {
    ...customer,
    enderecos: '',
    credenciamentos: ''
  }
  delete newCustomer?.enderecos
  delete newCustomer?.credenciamentos

  const newEnderecos = Array.isArray(customer?.enderecos?.data) ? customer.enderecos.data[0] : []

  const newCredenciamentos = []
  customer?.credenciamentos?.data?.map((item, index) => {
    newCredenciamentos.push({
      ...item,
      cliente: newCustomer?.uuid
    })
  })

  //Atualizando no backEnd - "clientes"
  await axios.put(`${API_BASE_URL}api/clientes/update?uuid=${customer.uuid}`, newCustomer)

  //Atualizando no backEnd - "enderecos"
  await axios.put(`${API_BASE_URL}api/enderecos/update?uuid=${newEnderecos.uuid}`, newEnderecos)

  //Atualizando no backEnd - "credenciamento"
  const response = await axios.put(`${API_BASE_URL}api/credenciamento/update`, newCredenciamentos)

  await dispatch(updateAlert({ text: "Alterações realizadas com sucesso", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData({page: 1, perPage: 10}))
  return response
})

export const addCustomer = createAsyncThunk('appCustomers/addCustomer', async (customer, { dispatch, getState }) => {
  const response = await axios.post(`${API_BASE_URL}api/clientes/create`, customer)

  return response
})

export const deleteUser = createAsyncThunk('appCustomers/deleteUser', async params => {

})

export const getAllData = createAsyncThunk('appCustomers/getAllData', async params => {

})

export const getUser = createAsyncThunk('appCustomers/getUser', async params => {

})

export const appCustomersSlice = createSlice({
  name: 'appCustomers',
  alertProps: { text: "", variant: "" },
  alertTrigger: false,
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    modelRehabSelected: {},
    currentCustomer: {
      step: 1,
      data: {}
    }
  },

  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(updateCurrentCustomer.fulfilled, (state, action) => {
        state.currentCustomer = action.payload
      })
      .addCase(getCurrentCustomer.fulfilled, (state, action) => {
        state.customerSelected = action.payload
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
  }
})

export default appCustomersSlice.reducer
