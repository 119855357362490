// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
import { data } from 'jquery'
import store from '../../configs/categories/store'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  let response = []
  if (params && params.search && params.search.value && params.search.value !== -1) {
    response = await axios.get(`${API_BASE_URL}api/users/filterByProfile?offset=${params.page}&limit=${params.perPage}&orderBy=name::asc&search=${params.search.value}`)
  } else {
    response = await axios.get(`${API_BASE_URL}api/users/list?offset=${params?.page ? params.page : 0}&limit=${params?.perPage ? params.perPage : 10}&orderBy=nome::asc`)
  }

  return {
    params,
    data: response.data.usuarios,
    totalPages: response.data.ordenate.totalItem
  }
})

export const setAlertTrigger = createAsyncThunk('appUsers/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})


export const updateAlert = createAsyncThunk('appUsers/updateAlert', async (props, { dispatch, getState }) => {
  return {text:props.text, variant:props.variant}
})

export const getUserSelected = createAsyncThunk('appUsers/getUserSelected', async user => {
  return user
})
export const updateUser = createAsyncThunk('appUsers/updateUser', async (data, { dispatch, getState}) => {
  await axios.put(`${API_BASE_URL}api/users/update?uuid=${data.uuid}`, data.data)
  await dispatch(getUserSelected({}))
  await dispatch(getData(getState()))
  // dispara um Alert apenas quando alterar informações diferente de status
  if (typeof Object.values(data)[1].status !== 'boolean') {
    await dispatch(updateAlert({text:"usuario atualizado com sucesso", variant:"success"}))
    await dispatch(setAlertTrigger(true))
  }

})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post(`${API_BASE_URL}api/users/create`, user)
  await dispatch(getData(getState()))
  await dispatch(updateAlert({text:"usuario criado com sucesso", variant:"success"}))
  await dispatch(setAlertTrigger(true))

})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (uuid, { dispatch, getState }) => {
    await axios.put(`${API_BASE_URL}api/users/delete?uuid=${uuid}`)
    await dispatch(getUserSelected({}))
    await dispatch(getData(getState()))

    await dispatch(updateAlert({text:"usuario excluido com sucesso", variant:"danger"}))
    await dispatch(setAlertTrigger(true))
      // window.location.reload()
})
export const toggleSideBar = createAsyncThunk('appUsers/toggleSideBar', async (toggle, { dispatch, getState }) => {
    return toggle
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selected: {},
    sidebarOpen: false,
    alertProps:{text:"", variant:""},
    alertTrigger:false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUserSelected.fulfilled, (state, action) => {
        state.selected = action.payload
      })
      .addCase(toggleSideBar.fulfilled, (state, action) => {
        state.sidebarOpen = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
  }
})

export default appUsersSlice.reducer
