// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const setAlertTrigger = createAsyncThunk('appAccessions/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const updateAlert = createAsyncThunk('appAccessions/updateAlert', async (props, { dispatch, getState }) => {
  return { text: props.text, variant: props.variant }
})

export const getData = createAsyncThunk('appAccessions/getData', async params => {
  let searchTerm = ``
  if ((params?.q?.planoId) || (params?.q?.formaPagamento) || (params?.q?.status) || (params?.q?.faturamento)) {
    if (params?.q?.planoId) {
      searchTerm += `&planoId=${params?.q?.planoId}`
    }
    if (params?.q?.status) {
      searchTerm += `&status=${params?.q?.status}`
    }
    if (params?.q?.faturamento) {
      searchTerm += `&faturamento=${params?.q?.faturamento}`
    }
  }

  let response = ''
  if (searchTerm === '') { 
    response = await axios.get(`${API_BASE_URL}api/adesoes/list?offset=${params?.page ? params.page : 0}&limit=${params?.perPage ? params.perPage : 10}&orderBy=criadoEm::asc`)
  } else {
    response = await axios.get(`${API_BASE_URL}api/adesoes/filter?offset=${params.page}&limit=10${searchTerm}&orderBy=criadoEm::asc`)
  }
  console.log('params', params, 'response', response)
  return {
    params,
    data: response.data.adesoes,
    totalPages: response.data.ordenate.totalItem
  }
})

export const getFiscal = createAsyncThunk('appAccessions/getFiscal', async params => {
 
  const response = await axios.get(`${API_BASE_URL}api/adesoes/fiscal`)
  return {
    params,
    data: response.data.adesoes,
    totalPages: response.data.ordenate.totalPage
  }
})

export const postHistoricoAdesao = createAsyncThunk('appAccessions/postHistoricoAdesao', async historico => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  historico.usuario = licenca.uuid
  const response = await axios.post(`${API_BASE_URL}api/historicoadesao/create`, historico)
})

export const updateCurrentAccession = createAsyncThunk('appAccessions/updateCurrentAccession', async (currentAccession, { dispatch, getState }) => {
  return currentAccession
})

export const getCurrentAccession = createAsyncThunk('appAccessions/getCurrentAccession', async params => {
  const response = await axios.get(`${API_BASE_URL}api/adesoes/search?offset=0&limit=10&orderBy=nome::asc&search=${params.uuid}`)

  const newBody = {
    params,
    step: params.step,
    data: response.data.adesoes[0],
    totalPages: response.data.ordenate.totalPage
  }
  return newBody
})

export const addAddress = createAsyncThunk('appAccessions/addAddress', async (address, { dispatch, getState }) => {
  const response = await axios.post(`${API_BASE_URL}api/enderecos/create`, address)
  return address
})

export const addAccession = createAsyncThunk('appAccessions/addAccession', async (accession, { dispatch, getState }) => {
  const acess = accession.clientAsaasId
  delete accession.clientAsaasId
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.post(`${API_BASE_URL}api/adesoes/create?clientAsaasId=${acess}&usuario=${licenca.uuid}`, accession)
  dispatch(getData(getState()))
  return response
})

export const updateAccession = createAsyncThunk('appAccessions/updateAccession', async (data, { dispatch, getState }) => {
  const {uuid, assinaturaAsaasId} = data
  const params = new URLSearchParams({ uuid })
  
  if (assinaturaAsaasId) {
    params.append('subscriptionId', assinaturaAsaasId)
  }

  const response = await axios.put(`${API_BASE_URL}api/adesoes/update?${params.toString()}`, data)
  await dispatch(getData(getState()))
  return response
})

export const deleteAccession = createAsyncThunk('appAccessions/deleteAccession', async (subscription, { dispatch, getState }) => {
  const { uuid, assinaturaAsaasId, tipoCobranca } = subscription
  const params = new URLSearchParams({ uuid })

  if (assinaturaAsaasId) {
    params.append('subscriptionId', assinaturaAsaasId)
  }

  if (tipoCobranca && tipoCobranca === 'gratuito') {
    params.append('billingType', tipoCobranca)
  }

  const response = await axios.put(`${API_BASE_URL}api/adesoes/delete?${params.toString()}`)
  await dispatch(getData(getState()))
  return response
})


export const updateStatus = createAsyncThunk('appAccessions/updateStatus', async (params, { dispatch, getState }) => {
  const response = await axios.put(`${API_BASE_URL}api/adesoes/update?uuid=${params.uuid}${params.assinaturaAsaasId ? `&subscriptionId=${params.assinaturaAsaasId}` : ''}`, {
    uuid: params.uuid,
    status:!params.status
  })
  await dispatch(getData(getState()))
  return response
})

export const appAccessionsSlice = createSlice({
  name: 'appAccessions',
  alertProps: { text: "", variant: "" },
  alertTrigger: false,
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedAccession: null,
    accessionSelected: {},
    currentAccession: {
      step: 1,
      data: {}
    }
  },

  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(updateCurrentAccession.fulfilled, (state, action) => {
        state.currentAccession = action.payload
      })
      .addCase(getCurrentAccession.fulfilled, (state, action) => {
        state.currentAccession = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
  }
})

export default appAccessionsSlice.reducer
