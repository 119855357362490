// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getFormData } from '../../../../utility/Utils'

// ** Axios Imports
import axios from '@src/services/api'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`


export const origemAdesao = createAsyncThunk('appUsers/origemAdesao', async params => {
  const response = await axios.get(`${API_BASE_URL}api/statusgeral/origemadesao?firstDate=${params?.firstDate}`)
  return { 
    params,
    data: response.data.datas,
    quantidades: response.data.quantidades,
    total: response.data.total,
    porcent: response.data.porcentagem
  }
})

export const adesaoGeralPlanos = createAsyncThunk('appUsers/adesaoGeralPlanos', async params => {
  const response = await axios.get(`${API_BASE_URL}api/statusgeral/adesaoplanos`)
  return { 
    params,
    data: response.data
  }
})

export const statusClientes = createAsyncThunk('appUsers/statusClientes', async params => {
  const response = await axios.get(`${API_BASE_URL}api/statusgeral/statusclientes`)
  return { 
    params,
    data: response.data
  }
})

export const adesoesSemana = createAsyncThunk('appUsers/adesoesSemana', async params => {
  let response
  if (params !== undefined) {
    response = await axios.get(`${API_BASE_URL}api/statusgeral/adesoessemana?firstDate=${params}`)
  }
  return { 
    params,
    data: response.data
  }
})

export const adesoesMeses = createAsyncThunk('appUsers/adesoesMeses', async params => {
  let response
  if (params !== undefined) {
    response = await axios.get(`${API_BASE_URL}api/statusgeral/adesoesmeses?firstDate=${params}`)
  }
  return { 
    params,
    datas: response.data.datas,
    quantidades: response.data.quantidades,
    progresso: response.data.progresso,
    total: response.data.total
  }
})

export const statusFinanceiro = createAsyncThunk('appUsers/statusFinanceiro', async params => {
  const response = await axios.get(`${API_BASE_URL}api/statusgeral/statusfinanceiro`)

  return {
    data: response.data
  }
})


export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    dataOrigemAdesao: [],
    quantidadeOrigemAdesao: [],
    totalOrigemAdesao: 0,
    porcentagemOrigemAdesao: 0,
    statusClientes: {},
    params: {},
    adesaoPlanos: [],
    adesaoSemana: [],
    adesaoMesesDatas: [],
    response: [],
    adesasMesesQuantidade: [],
    adesasMesesProgresso: [],
    adesasMesesTotal: 0,
    statusFinanceiroPlanos: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(origemAdesao.fulfilled, (state, action) => {
        state.dataOrigemAdesao = action.payload.data
        state.quantidadeOrigemAdesao = action.payload.quantidades
        state.totalOrigemAdesao = action.payload.total
        state.porcentagemOrigemAdesao = action.payload.porcent
      })
      .addCase(adesaoGeralPlanos.fulfilled, (state, action) => {
        state.adesaoPlanos = action.payload.data
      })
      .addCase(statusClientes.fulfilled, (state, action) => {
        state.statusClientes = action.payload.data
      })
      .addCase(adesoesSemana.fulfilled, (state, action) => {
        state.adesaoSemana = action.payload.data
      })
      .addCase(adesoesMeses.fulfilled, (state, action) => {
        state.adesaoMesesDatas = action.payload.datas
        state.adesasMesesQuantidade = action.payload.quantidades
        state.adesasMesesTotal = action.payload.total
        state.adesasMesesProgresso = action.payload.progresso
      })
      .addCase(statusFinanceiro.fulfilled, (state, action) => {
        state.statusFinanceiroPlanos = action.payload.data
      })
  }
})

export default appUsersSlice.reducer
