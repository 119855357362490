// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
import { getFormData } from '../../../../utility/Utils'
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const updateAlert = createAsyncThunk('appUsers/updateAlert', async (props, { dispatch, getState }) => {
  return {text:props.text, variant:props.variant}
})

export const setAlertTrigger = createAsyncThunk('appUsers/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const getIdModelo = createAsyncThunk('appUsers/getIdModelo', async (id, {dispatch}) => {
  return id
})

export const getModelo = createAsyncThunk('appUsers/getModelo', async (modelo, {dispatch}) => {
  if (typeof modelo?.uuid !== 'string') return []
  const response = await axios.get(`${API_BASE_URL}api/modeloavaliacao/search?offset=0&limit=10&orderBy=nome::asc&search=${modelo.uuid}`)
  const newResponse = response.data.modelo_avaliacao[0]

  //Calculando os campos "temImagem" dinamicamente, conforme existência
  //ou não do nome do arquivo no banco dados (tabela: "perguntas", campo "imagem")
  for (let i = 0; i < newResponse.grupo_perguntas.length; i++) {
    const grupo = newResponse.grupo_perguntas[i]
    for (let j = 0; j < grupo.perguntas.length; j++) {
      const pergunta = grupo.perguntas[j]

      // *** Esses campos não existem no banco de dados, são calculados em "tempo de execução"
      //1-Sim possui imagem, 2-Não possui imagem
      newResponse.grupo_perguntas[i].perguntas[j].temImagem = typeof newResponse.grupo_perguntas[i].perguntas[j].imagem === 'string' ? '1' : '2'

      //Tipo de Resposta
      //newResponse.grupo_perguntas[i].perguntas[j].tipoDeResposta = newResponse?.grupo_perguntas[i]?.perguntas[j]?.respostas[0]?.tipoDeResposta
    }
  }

  return newResponse
})

export const getModeloData = createAsyncThunk('appUsers/getModeloData', async params => {
  const response = await axios.get(`${API_BASE_URL}api/modeloavaliacao/list?offset=${params.page}&limit=${params.perPage}&orderBy=nome::asc`)
  return {
    params,
    data: response.data.modelo_avaliacao,
    totalPages: response.data.ordenate.totalItem
  }
})

export const addModeloAvaliacao = createAsyncThunk('appUsers/addModeloAvaliacao', async (modelo, { dispatch, getState }) => {
  await axios.post(`${API_BASE_URL}api/modeloavaliacao/create`, getFormData(modelo))
  // getFormData({
  //   modeloAvaliacao: [...modelo]
  // }))
  await dispatch(updateAlert({text:"Modelo de avaliação cadastrado com sucesso", variant:"success"}))
  await dispatch(setAlertTrigger(true))
  await dispatch(getModeloData(getState()))
  return modelo
})

export const duplicarModeloAvaliacao = createAsyncThunk('appUsers/duplicarModeloAvaliacao', async (uuid, { dispatch, getState }) => {
  await dispatch(updateAlert({text:"Modelo de avaliação duplicado com sucesso", variant:"success"}))
  await dispatch(setAlertTrigger(true))
  await axios.put(`${API_BASE_URL}api/modeloavaliacao/duplicar?search=${uuid}&limit=10&offset=0`)
  await dispatch(getModeloData(getState()))
  return modelo
})

export const updateModeloAvaliacao = createAsyncThunk('appUsers/updateModeloAvaliacao', async (modelo, { dispatch, getState }) => {
  if (modelo.modelo) {
    await axios.put(`${API_BASE_URL}api/modeloavaliacao/update?uuid=${modelo.uuid}`, modelo.modelo)
  }
  if (modelo.grupoDePergunta) {
    const response = await axios.put(`${API_BASE_URL}api/grupopergunta/update`, modelo.grupoDePergunta)
  }
  if (modelo.grupoDePerguntaDeletado) {
    const response = await axios.put(`${API_BASE_URL}api/grupopergunta/update`, modelo.grupoDePerguntaDeletado)
  }
  if (modelo.perguntas) {
    await axios.put(`${API_BASE_URL}api/pergunta/update`, getFormData(modelo.perguntas)) //esta rota possui upload
  }
  if (modelo.respostas) {
    await axios.put(`${API_BASE_URL}api/resposta/update`, modelo.respostas)
  }
  if (modelo.opcoesAdicionais) {
    await axios.put(`${API_BASE_URL}api/opcoesadicionaisalternativa/update`, modelo.opcoesAdicionais)
  }
  await dispatch(updateAlert({text:"Modelo de avaliação atualizado com sucesso", variant:"success"}))
  await dispatch(setAlertTrigger(true))
  await dispatch(getModeloData(getState()))
  return modelo
})

export const updateStatus = createAsyncThunk('appUsers/updateStatus', async (modelo, { dispatch, getState }) => {
  await axios.put(`${API_BASE_URL}api/modeloavaliacao/update?uuid=${modelo.uuid}`, {
    status:!modelo.status
  })
  await dispatch(getModeloData(getState()))
  return modelo
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteInternoModelo = createAsyncThunk('appUsers/deleteInternoModelo', async (modelo, { dispatch, getState }) => {
  if (modelo.grupoDePergunta) {
    await axios.put(`${API_BASE_URL}api/grupopergunta/delete`, modelo.grupoDePergunta.uuid)
  }
  if (modelo.perguntas) {
    await axios.put(`${API_BASE_URL}api/pergunta/delete`, modelo.perguntas.uuid)
  }
  if (modelo.respostas) {
    await axios.put(`${API_BASE_URL}api/resposta/delete`, modelo.respostas.uuid)
  }
  await dispatch(getModelo(modelo, getState()))
  return modelo
})

export const deleteModelo = createAsyncThunk('appUsers/deleteModelo', async (modeloId, { dispatch, getState }) => {
  await dispatch(updateAlert({text:"Modelo de avaliação excluído com sucesso", variant:"danger"}))
	await dispatch(setAlertTrigger(true))
  await axios.put(`${API_BASE_URL}api/modeloavaliacao/delete?uuid=${modeloId.uuid}`)
  await dispatch(getModeloData(getState()))
  return modeloId
})

export const setToggleAlert = createAsyncThunk('appUsers/setToggleAlert', async (alert, { dispatch, getState }) => {
  return {
    message:alert.message,
    active:alert.active
  }
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedModelo: null,
    id: null,
    alertProps:{text:"", variant:""},
    alertTrigger:false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getModeloData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getModelo.fulfilled || getModelo.rejected, (state, action) => {
        state.selectedModelo = action.payload
      })
      .addCase(getIdModelo.fulfilled, (state, action) => {
        state.id = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
  }
})

export default appUsersSlice.reducer
