// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
import { getFormData } from '../../../../utility/Utils'

export const getCurrentUser = createAsyncThunk('appMyProfile/getCurrentUser', async params => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/users/search?offset=0&limit=10&orderBy=nome::asc&search=${params.uuid}`)

  return {
    params,
    data: response.data.usuarios[0],
    totalPages: response.data.ordenate.totalPage
  }
})

export const setAlertTrigger = createAsyncThunk('appMyProfile/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const updateAlert = createAsyncThunk('appMyProfile/updateAlert', async (props, { dispatch, getState }) => {
  return { text: props.text, variant: props.variant }
})

export const updateCurrentUser = createAsyncThunk('appMyProfile/updateCurrentUser', async (currentUser, { dispatch, getState }) => {
  return currentUser
})

export const updateUser = createAsyncThunk('appMyProfile/updateUser', async (user, { dispatch, getState }) => {
  //Atualizando no backEnd - "modelo questionário"
  const response = await axios.put(`${process.env.REACT_APP_API_URL}api/users/update?uuid=${user.uuid}`, getFormData(user.fields))

  if (response?.data?.update_usuarios_by_pk?.uuid) {
    await dispatch(getCurrentUser(user))
  }
  return user
})

export const appMyProfileSlice = createSlice({
  name: 'appMyProfile',
  alertProps: { text: "", variant: "" },
  alertTrigger: false,
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    currentUser: {
      step: 1,
      data: {}
    }
  },

  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload
      })
  }
})

export default appMyProfileSlice.reducer
