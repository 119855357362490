// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'
import { getFormData } from '../../../../utility/Utils'
import { deleteInternoModelo } from '../../evaluations/store'

const API_BASE_URL = `${process.env.REACT_APP_API_URL}`

export const getData = createAsyncThunk('appModelRehab/getData', async params => { 
  const response = await axios.get(`${API_BASE_URL}api/modeloreabilitacao/list?offset=${params?.page}&limit=${params?.perPage}&orderBy=nome::asc`)
 
  return {
    params,
    data: response.data.modelo_reabilitacao,
    totalPages: response.data.ordenate.totalItem
  }
})

export const deleteUser = createAsyncThunk('appModelRehab/deleteUser', async params => {

})

export const updateCurrentRehab = createAsyncThunk('appModelRehab/updateCurrentRehab', async (currentRehab, { dispatch, getState }) => {
  return currentRehab
})

export const updateAlert = createAsyncThunk('appModelRehab/updateAlert', async (props, { dispatch, getState }) => {
  return { text: props.text, variant: props.variant }
})

export const setAlertTrigger = createAsyncThunk('appModelRehab/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const addModeloReabilitacao = createAsyncThunk('appModelRehab/addModeloReabilitacao', async (modelo, { dispatch, getState }) => {
  await axios.post(`${API_BASE_URL}api/modeloreabilitacao/create`, getFormData(modelo))
  await dispatch(updateAlert({ text: "Modelo de reabilitação cadastrado com sucesso", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return modelo
})

export const getCurrentRegister = createAsyncThunk('appModelRehab/getCurrentRegister', async params => {
  const response = await axios.get(`${API_BASE_URL}api/modeloreabilitacao/search?offset=0&limit=10&orderBy=nome::asc&search=${params.uuid}`)

  //Ajustando/Trabalhando a requisição, conforme necessidade frontEnd
  const newRes = response?.data?.modelo_reabilitacao[0]
  await newRes?.sessao?.map((session, indexSession) => {

    session?.grupo_perguntas?.map((group, indexGroup) => {

      const arrPerguntas = []
      group?.perguntas?.map((question, indexQuestion) => {
        const newQuestion = {
          uuid: question?.uuid,
          explicacaoAdicional: question?.explicacaoAdicional,
          imagem: question?.imagem,
          ordemPergunta: question?.ordemPergunta,
          pergunta: question?.pergunta,
          quantidadeDeAlternativas: question?.quantidadeDeAlternativas,
          respostas: question?.respostas,
          tipoDeResposta: question?.tipoDeResposta,
          temImagem: question?.imagem?.length > 0 ? '1' : '2',
          tipoUpload: 'Reabilitacao',
          enabledQuantidadeDeAlternativas: !((question?.tipoDeResposta === 'texto livre') || (question?.tipoDeResposta === 'unica')),
          frequenciaIdeal: question?.frequenciaIdeal,
          periodo: question?.periodo,
          diasEmAusencia: question?.diasEmAusencia,
          nomeVideo: question?.nomeVideo,
          descricaoVideo: question?.descricaoVideo,
          videoStream: question?.videoStream,
          thumbnailStream: question?.thumbnailStream,
          quantidadeSimbolos: question?.quantidadeSimbolos,
          perguntaSimbolos: question?.perguntaSimbolos,
          velocidadeVideo: question?.velocidadeVideo,
          concepcaoTecnicoCientifica: question?.concepcaoTecnicoCientifica,
          desenvolvimento: question?.desenvolvimento,
          gabarito: question?.gabarito,
          constrasteVideo: question?.constrasteVideo,
          permiteAlterarVelocidade: question?.permiteAlterarVelocidade,
          permiteAlterarConstrate: question?.permiteAlterarConstrate
        }

        arrPerguntas.push(newQuestion)
      })

      newRes.sessao[indexSession].grupo_perguntas[indexGroup].perguntas = arrPerguntas
    })
  })

  return {
    params,
    data: newRes,
    totalPages: response.data.ordenate.totalPage
  }
})

export const updateModeloReabilitacao = createAsyncThunk('appModelRehab/updateModeloReabilitacao', async (modelo, { dispatch, getState }) => {
  
    const currentRehabBody = {
      status: modelo.status,
      nome: modelo.nome,
      tipoAtividade: modelo.tipoAtividade,
      categoriaAtividade: modelo.categoriaAtividade,
      dependeReabilitacao: modelo.dependeReabilitacao
    }
  
    const arrSessionsBody = []
    const arrGroupQuestionsBody = []
    const arrQuestionsBody = []
    const arrAnswersBody = []
  
    //Percorrendo as Sessões
    modelo.sessao.map((session, indexSession) => {
  
      // Percorrendo o Grupo de Perguntas
      session.grupoPerguntas.map((group, indexGroup) => {
  
        //Percorrendo as Perguntas
        group.perguntas.map((question, indexQuestion) => {
  
          //Percorrendo as Respostas
          question.respostas.map((answer, indexAnswer) => {
            const newAnswer = {
              uuid: answer.uuid,
              tipoDeResposta: answer.tipoDeResposta,
              ordemAlternativa: answer.ordemAlternativa,
              respostaAlternativa: answer.respostaAlternativa,
              pergunta: answer.pergunta,
              imagem: answer.imagem,
              correta: answer.correta,
              deletado: answer.deletado
            }
  
            arrAnswersBody.push(newAnswer)
          })
          console.log('question NA STORE', question)
          const newQuestion = {
            uuid: question.uuid,
            ordemPergunta: Number(question.ordemPergunta),
            quantidadeDeAlternativas: Number(question.quantidadeDeAlternativas),
            imagem: question.imagem,
            pergunta: question.pergunta,
            tipoDeResposta: question?.tipoDeResposta,
            explicacaoAdicional: question.explicacaoAdicional,
            grupoDePergunta: group.uuid,
            tipoUpload: question.tipoUpload,
            frequenciaIdeal: question.frequenciaIdeal,
            periodo: question.periodo,
            diasEmAusencia: question.diasEmAusencia,
            nomeVideo: question.nomeVideo,
            descricaoVideo: question.descricaoVideo,
            videoStream: question.videoStream,
            thumbnailStream: question.thumbnailStream,
            quantidadeSimbolos: question.quantidadeSimbolos,
            idModelo: question.idModelo,
            nomeCategoria: session.nome,
            concepcaoTecnicoCientifica: question?.concepcaoTecnicoCientifica,
            desenvolvimento: question?.desenvolvimento,
            gabarito: question?.gabarito,
            perguntaSimbolos: question.perguntaSimbolos,
            velocidadeVideo: question.velocidadeVideo,
            constrasteVideo: question.constrasteVideo,
            permiteAlterarVelocidade: question.permiteAlterarVelocidade,
            permiteAlterarConstrate: question.permiteAlterarConstrate
          }
  
          arrQuestionsBody.push(newQuestion)
        })
  
        const newGroup = {
          uuid: group.uuid,
          nome: group.nome,
          descricao: group.descricao,
          ordemGrupoPergunta: Number(group.ordemGrupoPergunta),
          explicacaoAdicional: group.explicacaoAdicional,
          quantidadePerguntas: Number(group.quantidadePerguntas),
          modeloAvaliacao: group.modeloAvaliacao,
          modeloReabilitacao: modelo.uuid,
          sessao: session.uuid
        }
  
        if (newGroup.uuid) {
          arrGroupQuestionsBody.push(newGroup)
        }
      })
  
      const newSession = {
        uuid: session.uuid,
        nome: session.nome,
        modeloReabilitacao: modelo.uuid,
        dependenciaDeSessao: session?.dependenciaDeSessao?.length > 0 ? session.dependenciaDeSessao : null,
        prosseguimentoParcialDeSessao: session?.prosseguimentoParcialDeSessao?.length > 0 ? session.prosseguimentoParcialDeSessao : null,
        ordemSessao: session.ordemSessao
      }
  
      if (newSession?.uuid) {
        arrSessionsBody.push(newSession)
      }
  
    })
    
    //Atualizando no backEnd - "modelo_reabilitacao"
    await axios.put(`${API_BASE_URL}api/modeloreabilitacao/update?uuid=${modelo.uuid}`, currentRehabBody)
  
    //Atualizando no backEnd - "sessao"
    await axios.put(`${API_BASE_URL}api/sessao/update`, arrSessionsBody)
  
    //Atualizando no bakcEnd - "grupo_perguntas"
    await axios.put(`${API_BASE_URL}api/grupopergunta/update`, arrGroupQuestionsBody)
  
    //Atualizando no backEnd - "perguntas"
    await axios.put(`${API_BASE_URL}api/pergunta/update`, getFormData(arrQuestionsBody))
  
    //Atualizando no backEnd - "respostas"
    await axios.put(`${API_BASE_URL}api/resposta/update`, arrAnswersBody)
  
    await dispatch(updateAlert({ text: "Modelo de reabilitação atualizado com sucesso", variant: "success" }))
    await dispatch(setAlertTrigger(true))
    await dispatch(getData(getState()))
  
  return modelo
})

export const updateStatus = createAsyncThunk('appModelRehab/updateStatus', async (params, { dispatch, getState }) => {
  await axios.put(`${process.env.REACT_APP_API_URL}api/modeloreabilitacao/update?uuid=${params.uuid}`, {
    status: !params.status
  })

  await dispatch(getData(getState()))
  return params
})

export const deleteRehab = createAsyncThunk('appModelRehab/deleteRehab', async (params, { dispatch, getState }) => {
  //Apagando Reabilitação
  await axios.put(`${process.env.REACT_APP_API_URL}api/modeloreabilitacao/delete?uuid=${params.uuid}`)

  await dispatch(getData(getState()))
  await dispatch(updateAlert({ text: "Modelo de reabilitação excluído com sucesso", variant: "danger" }))
  await dispatch(setAlertTrigger(true))
})

export const appModelRehabSlice = createSlice({
  name: 'appModelRehab',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    resources: [],
    paymentForms: [],
    resourceSelected: {},
    modelRehabSelected: {},
    modal: false,
    alertProps: { text: "", variant: "" },
    alertTrigger: false,
    paymentFormsSelected: [],
    invoicingData: [],
    invoicingCreated: [],
    currentRehab: {
      step: 1,
      data: {}
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(updateCurrentRehab.fulfilled, (state, action) => {
        state.currentRehab = action.payload
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(getCurrentRegister.fulfilled, (state, action) => {
        state.modelRehabSelected = action.payload
      })
  }
})

export default appModelRehabSlice.reducer